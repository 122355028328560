@import url("./LineUp_960.css");
@import url("./LineUp_744.css");
@import url("./LineUp_375.css");

@media screen and (min-width: 1280px) {

    .lineUp__wrapper {
        background-color: #fff;
        padding: 40px 0px 49px 0px;
        border-radius: 20px;
        margin-bottom: 10px;
    }
    
    .lineUp__title {
        margin-left: 39px;
        margin-bottom: 18px;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
        transition: all 0.5s;
    }
    
    .lineUp__diagram {
        background: url('../../../Media/lineUpDiagram.svg') center no-repeat;
        background-size: contain;
        height: 673px;
        width: 1049px;
        margin: 0 auto;
        z-index: 50;
        transition: all 0.5s;
    }

}
