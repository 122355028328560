@media screen and (max-width: 743px) {
  
    .readyMechanics__wrapper {
        background-color: #141414;
        padding: 20px 0px 25px 0px;
        border-radius: 20px;
    }

    .readyMechanics__title {
        color: #fff;
        font-weight: 400;
        font-size: 42px;
        line-height: 94%;
        letter-spacing: -0.03em;
        margin-left: 22px;
        margin-bottom: 15px;
        max-width: 238px;
    }

    .readyMechanics__caption {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #BDBDBD;
        margin-left: 25px;
        max-width: 214px;
        margin-bottom: 100px;
    }

    .readyMechanics__list {
        padding: 0px 15px 0px 15px;
        display: flex;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;
    }

    .readyMechanics__list-item {
        flex: 100%;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
    }

}

