@import url("./CaseCard_744.css");
@import url("./CaseCard_375.css");

@media screen and (min-width: 960px) {
    
    .caseCard {
        width: 100%;
        max-width: 628px;
        min-width: 360px;
        height: 360px;
        border-radius: 20px;
    }

    .caseCard__head {
        height: 130px;
        border-radius: 20px 20px 0px 0px;
        position: relative;
        overflow: hidden;
    }
    
    .caseCard__head-img {
        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0px 0px;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        object-fit:cover;

    }
    
    .caseCard__logo {
        height: 70px;
        width: 70px;
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 99;
    }
    
    .caseCard__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 30px 30px 30px;
        height: calc(100% - 130px);
    }
    
    .caseCard__mid {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
    
    .caseCard__name {
        margin-top: 20px;
    }
    
    .caseCard__name h3 {
        font-size: 22px;
        margin-bottom: 5px;
    }
    
    .caseCard__name p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
    }
    
    .caseCard__rating {
        display: flex;
        margin-top: 25px;
        gap: 5px;
    }
    
    .caseCard__rating p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #141414;
    }
    
    .caseCard__rating-star {
        height: 14px;
        width: 14px;
        background: url('../../../Media/RatingStar.svg') center no-repeat;
        background-size: contain;
    }

    .caseCard__ready-mechanics {
        display: flex;
        margin-top: 25px;
        gap: 8px;
    }
    
    .caseCard__ready-mechanics p {
        align-self: flex-start;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #141414;
        position: relative;
        margin-right: 18px;
    }

    .caseCard__ready-mechanics p::after {
        position: absolute;
        content: "\2022";
        font-size: 43px;
        color: #CEEB1A;
        right: -20px;
        top: -2px;
    }
    
    .caseCard__description {
        max-width: 328px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        align-items: flex-end;
        color: #6D6D6D;
    }

}
