@media screen and (max-width: 960px) {
    
    .contactsHead__wrapper {
        padding: 5px 0px 5px 0px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .contactsHead__banner {
        height: 500px;
        width: 100%;
        padding: 30px 0px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .contactsHead__banner-top {
        display: none;
    }

    .contactsHead__banner-bottom {
        display: flex;
        justify-content: space-between;
    }

    .contactsHead__banner-titles h1 {
        margin-left: 22px;
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 42px;
        line-height: 94%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .contactsHead__banner-titles p {
        margin-left: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
        max-width: 210px;
    }

    .contactsHead__banner-cutaway {
        align-self: flex-end;
        margin-right: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .contactsHead__banner-cutaway p {
        width: 70px;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        text-align: center;
        color: #141414;
    }

    .contactsHead__banner-qr {
        height: 65px;
        width: 65px;
        background: url('../../../Media/BusinessCardLink.svg') center no-repeat;
        background-size: contain;
    }

    .contactsHead__map {
        position: relative;
        height: 470px;
        width: 100%;
        /* max-width: 828px; */
        background-color: black;
        border-radius: 20px;
        overflow: auto;
        -webkit-user-select: none;
        user-select: none;
        /* position: relative; */
        overflow: hidden;
    }

    .contactsHead__map img {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        user-select: none;
    }

}