@import url('./Promo_744.css');

@media screen and (min-width: 960px) {
    
    .promo__wrapper {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
    }

    .promo__content {
        height: 627px;
        width: 100%;
        max-width: 828px;
        min-width: 576px;
        padding: 40px 30px 30px 30px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .promo__content h2 {
        margin-left: 10px;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
        margin-bottom: 18px;
    }

    .promo__content p {
        margin-left: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
        flex: start;
    }

    .promo__slider {
        height: 433px;
        width: 100%;
        /* max-width: 827px; */
        border-radius: 20px;
        color: #fff;
        position: relative;
        overflow: hidden;
        
    }

    .promo__slide {
        height: 433px;
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .promo__slide img {
        width: 100%;
        height: 100%;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        object-fit:cover;
    }

    .promo__slider-arrows {
        height: 60px;
        width: 120px;
        border-radius: 16px;
        position: absolute;
        bottom: 25px;
        right: 35px;
        display: flex;
        z-index: 997;
    }

    .promo__slider-arrow-left {
        border-radius: 16px 0px 0px 16px;
        background: url('../../../Media/SliderArrowLeft.svg') center no-repeat;
    }

    .promo__slider-arrow-right {
        border-radius: 0px 16px 16px 0px;
        background: url('../../../Media/SliderArrowRight.svg') center no-repeat;
    }

    .promo__slider-arrows > .promo__slider-arrow-left, .promo__slider-arrow-right {
        height: 100%;
        width: 50%;
        background-size: 10px 20px;
        background-color: rgba(189, 189, 189, 0.4);
        backdrop-filter: blur(4px);
        cursor: pointer;
    }

    .promo__inf {
        height: 627px;
        width: 100%;
        max-width: 508px;
        min-width: 354px;
        padding: 40px 40px 48px 40px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .promo__inf h2, p {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #141414;
    }

    .promo__inf h2 {
        margin-bottom: 28px;
    }

    .promo__inf p {
        color: #6D6D6D;
    }

    .promo__tools h2 {
        margin-bottom: 23px;
    }

    .promo__tools-list {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
    }

    .promo__tool {
        height: 30px;
        padding: 0px 12px;
        display: flex;
        align-items: center;
        border: 1px solid #BDBDBD;
        border-radius: 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
    }

}