@media screen and (min-width: 744px) and (max-width: 959px) {

    .topBlock__wrapper {
        padding: 10px 0px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .topBlock__banner {
        height: 500px;
        width: 100%;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        border-radius: 20px;
        padding: 30px 0px 25px 0px;
        box-sizing: border-box;
    }
    
    .topBlock__logo {
        height: 32px;
        margin-left: 30px;
        align-self: start;
        transition: all 0.5s;
    }
    
    .topBlock__banner-left {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    
    .topBlock__banner-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .topBlock__nav-wrapper {
        align-self: flex-end;
    }
    
    .topBlock__titles {
        margin-bottom: 5px;
    } 
    
    .topBlock__title {
        margin-bottom: 15px;
        margin-left: 27px;
        transition: all 0.5s;
        font-size: 42px;
    }
    
    .topBlock__label {
        margin-left: 29px;
        transition: all 0.5s;
    }
    
    .topBlock__stamp {
        background: url('../../../Media/stamp.png') center no-repeat;
        background-size: contain;
        height: 94px;
        width: 94px;
        align-self: flex-end;
        margin-right: 30px;
        transition: all 0.5s;
    }
    
    .topBlock__partners {
        order: 2;
        height: 220px;
        width: 100%;
        box-sizing: border-box;
        background-color: #141414;
        border-radius: 20px;
        padding: 20px 0px;
    }
    
    .topBlock__partners-title {
        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        margin-left: 29px;
        margin-bottom: 33px;
    }
    
    .topBlock__partners-label {
        display: none;
    }

    .topBlock__ticker {
        position: relative;
        display: flex;
        overflow: hidden;
        user-select: none;
        height: 100px;
        gap: 70px;
    }

    .topBlock__ticker-items {
        flex-shrink: 0;
        display: flex;
        gap: 70px;
        justify-content: space-around;
      }
      
    .topBlock__ticker-item {
        height: 100px;
        /* transition: all 0.1s ease-in-out; */
    }
    
    .marquee {
        animation: scroll 120s linear infinite;
    }
      
    @keyframes scroll {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(calc(-100% - 70px));
        }
    }
    
    .topBlock__slider {
        height: 855px;
        width: 100%;
        border-radius: 20px;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        background-color: #141414;
    }

    .swiper {
        height: 100%;
        width: 100%;
        border-radius: 20px;
    }

    .swiper-slide {
        border-radius: 20px;
    }

    .topBlock__slider-title {
        position: absolute;
        color: #fff;
        z-index: 99;
        top: 30px;
        left: 30px;
        font-size: 16px;
    }

    .topBlock__slide {
        height: 100%;
        width: 100%;
        border-radius: 20px;
        padding: 0px 20px 44px 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 !important;
        margin-bottom: 11px;
      }
    
    
      .swiper-pagination-bullet {
        width: 40px !important;
        height: 2px !important;
        margin: 0 !important;
        opacity: 1;
        border-radius: 0px !important;
        background-color: white !important;
      }
      
      .swiper-pagination-bullet-active {
        height: 4px !important;
        border-radius: 10px !important;
        background: white !important;
      }

      .topBlock__slide__card {
        width: 100%;
        height: 88px;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
        display: flex;
        gap: 15px;
        padding: 19px 19px 0px 19px;
      }

      .topBlock__slide__card-icon {
        height: 50px;
        width: 50px;
        background-color: #141414;
        border-radius: 16px;
      }

      .topBlock__slide__card-title {
        margin-top: 7px;
        flex-grow: 1;
      }

      .topBlock__slide__card-title h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
      }

      .topBlock__slide__card-title p {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #6D6D6D;
      }

      .topBlock__slide__cart-case {
        display: none;
      }
}
