@media screen and (min-width: 960px) and (max-width: 1279px) {

    .strengths__header {
        height: 335px;
        width: 100%;
        background-color: #141414;
        border-radius: 20px;
        padding: 30px 30px 30px 26px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
        transition: all 0.5s;
    }
    
    .strengths__header-title {
        font-style: normal;
        font-weight: 400;
        font-size: 46px;
        line-height: 94%;
        color: #fff;
        letter-spacing: -0.03em;
    }
    
    .strengths__header-text {
        align-self: flex-end;
        color: #fff;
        max-width: 528px;
    }
    
    .strengths__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px
    }
    
    .strength__card {
        height: 458px;
        flex: 49%;
        background-color: #fff;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 80px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transition: all 0.5s;
    }

    .clientsReview {
        height: 515px;
    }

    .clientsReview .strength__card-text {
        margin-top: 50px;
    }
    
    .strength__card-head {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .strength__card-head h3 {
        max-width: 231px;
        text-align: center;
        font-size: 22px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #141414;
    }
    
    .strength__card-head {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .strength__card-icon {
        height: 76px;
        width: 76px;
        border-radius: 22px;
        margin-bottom: 20px;
    }
    
    .strength__card-text {
        max-width: 385px;
        text-align: center;
        transition: all 0.5s;
    }

    .strength__card-client {
        text-align: center;
    }

    .strength__card-client h4 {
        font-size: 16px;
        line-height: 120%;
        color: #141414;
    }

    .strength__card-client p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
    }

}