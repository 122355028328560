@media screen and (min-width: 744px) and (max-width: 959px) {
    
    .caseList__item {
        height: 100px;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        gap: 30px;
        height: 341px;
        background-color: #fff;
        border-radius: 20px;
    }
    
    .even-item {
        flex-direction: row-reverse;
    }
    
    .caseList__item-category {
        height: 100%;
        width: 100%;
        min-width: 337px;
        background-color: #fff;
        border-radius: 20px 0px 0px 20px;
        padding: 30px 0px 30px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .caseList__item-title {
        font-size: 22px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
        max-width: 325px;
        margin-bottom: 18px;
        transition: all 0.5s;
    }
    
    .caseList__item-caption {
        font-size: 14px;
        max-width: 240px;
        transition: all 0.5s;
        color: #6D6D6D;
    }
    
    .caseList__item__cutaway {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    .caseList__item__cutaway.mobile {
        display: none;
    }
    
    .caseList__item__cutaway p {
        align-self: flex-end;
        max-width: 105px;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: #141414;
    }
    
    .caseList__item__qr-code {
        height: 65px;
        width: 65px;
        background: url('../../../Media/BusinessCardLink.svg') center no-repeat;
        background-size: contain;
        margin-right: 20px;
    }
    
    .caseList__item-case {
        height: 100%;
        width: 100%;
        max-width: 827px;
        min-width: 357px;
        padding: 0px 30px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 0px 20px 20px 0px;
    }

    .even-item .caseList__item-case {
        padding: 0px 0px 0px 30px;
        border-radius: 20px 0px 0px 20px;
    }

    .even-item .caseList__item-category {
        padding: 30px 30px 30px 0px;
        border-radius: 0px 20px 20px 0px;
    }
}