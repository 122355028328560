@media screen and (max-width: 960px) {
    .header {
        padding-top: 5px;
        position: fixed;
        top: 0px;
        width: 100%;
        z-index: 999;
        box-sizing: border-box;
        display: block;
        background: linear-gradient(to bottom, #E9E9E9 40px, transparent 40px);
        animation: hide-header 0.5s;
    }
    
    .header__content {
        height: 65px;
        background: #fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 28px 0px 25px;
    }
    
    .header__logo-link {
        height: 26px;
        width: 110px;
    }
    
    .header__logo {
        height: 26px;
        width: 110px;
    }
    
    .header__nav-list {
        display: flex;
        gap: 25px;
    }
    
    .header__nav-list-item:hover {
        text-decoration: underline;
    }
    
    .header__service-list {
        display: flex;
        gap: 25px;
    }
    
    .header__service-list-item:first-child {
        color: #BDBDBD;
    }
    
    .header__service-list-item:hover {
        text-decoration: underline;
    }
    
    .header__service-list-item:first-child:hover {
        text-decoration: none;
    }
    
    .header__presentation:hover {
        text-decoration: underline;
    }
}