@import url("./FeedbackForm_960.css");
@import url("./FeedbackForm_744.css");
@import url("./FeedbackForm_375.css");


@media screen and (min-width: 1280px) {

    .feedbackForm {
        height: 654px;
        width: 100%;
        max-width: 508px;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 20px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
    }

    .feedbackForm form {
        position: relative;
        margin-bottom: 30px;
    }
    
    .feedbackForm__heading {
        height: 66px;
        min-height: 66px;
        display: flex;
        align-items: center;
        padding-left: 40px;
        border-bottom: 1px solid #E7E7E7;
    }
    
    .feedbackForm__heading-title {
        font-family: 'TT Hoves', sans-serif;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #6D6D6D;
        margin-right: 10px;
    }
    
    .feedbackForm__heading-request {
        height: 43px;
        width: 106px;
        background: #E9E9E9;
        border-radius: 30px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .feedbackForm__input {
        font-family: 'TT Hoves', sans-serif;
        width: 100%;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
        box-sizing: border-box;
        padding: 20px 61px 20px 40px;
        resize: none;
        border-bottom: 1px solid #E7E7E7;
    }

    .feedbackForm__input.input-error {
        color: #FF3641;
        padding: 25px 61px 15px 40px;
    }

    .feedbackForm__input::placeholder {
        color: #6D6D6D;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
    }

    .feedbackForm__input.input-error::placeholder {
        color: #FF3641;
    }

    .feedbackForm__textariablock {
        margin: 20px 25px 0px 40px;
        height: 230px;
    }

    .feedbackForm__input.textaria {
        padding: 0px 36px 0px 0px;
        width: 100%;
        height: 100%;
        border-bottom: none;
    }

    .textaria.input-error {
        padding-top: 2px;
    }

    .error-message {
        position: absolute;
        display: block;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .error-message.name {
        top: 12px;
        left: 40px;
    }
    .error-message.email {
        top: 78px;
        left: 40px;
    }
    .error-message.phone {
        top: 144px;
        left: 40px;
    }
    .error-message.message {
        top: 212px;
        left: 40px;
    }

    .feedbackForm__bottom {
        width: 100%;
        height: 76px;
        padding: 0px 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .feedbackForm__rules {
        max-width: 300px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .feedbackForm__rules a {
        text-decoration: underline;
    }

    .feedbackForm__button {
        height: 70px;
        width: 70px;
        border-radius: 20px;
        background: url('../../../Media/ButtonArrow.svg') center no-repeat;
        background-size: 23px;
        background-color: #141414;
        cursor: pointer;
    }

    .feedbackForm__button.fb-disabled {
        background: url('../../../Media/ButtonArrowDisabled.svg') center no-repeat;
        background-size: 23px;
        background-color: #141414;
        pointer-events: none;
    }

    /* .feedbackForm__button:hover {
        height: 76px;
        width: 76px;
    } */

    .feedback__sent {
        height: 100%;
        width: 100%;
        padding-top: 41px;
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .feedback__sent h2 {
        font-weight: 400;
        font-size: 46px;
        line-height: 94%;
        letter-spacing: -0.03em;
        color: #141414;
        margin-left: 40px;
        margin-bottom: 12px;
    }

    .feedback__sent p {
        flex-grow: 1;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
        margin-left: 42px;
    }

    .feedback__sent .feedbackForm__button {
        align-self: flex-end;
        background: url('../../../Media/ButtonSuccess.svg') center no-repeat;
        background-size: 23px;
        background-color: #141414;
    }

    .feedbackForm__required {
        position: absolute;
        right: 40px;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
    }

}