@media screen and (max-width: 744px) {

    .rateCase__wrapper {
        display: flex;
        flex-direction: column;
    }

    .rateCase__wrapper > div {
        width: 100%;
        background: #fff;
    }

    .rateCase__specification {
        max-width: 987px;
        padding: 25px 0px 60px 0px;
        border-radius: 20px 20px 0px 0px;
    }

    .rateCase__specification h2 {
        margin-bottom: 18px;
        margin-left: 22px;
        font-weight: 400;
        font-size: 22px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .rateCase__specification p {
        max-width: 398px;
        margin-left: 24px;
        margin-right: 25px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .rateCase__rating {
        border-radius: 0px 0px 20px 20px;
        padding: 00px 0px 40px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .rateCase__rating-qr {
        height: 119px;
        width: 119px;
        margin-bottom: 20px;
    }

    .rateCase__rating-label {
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        text-align: center;
        color: #6D6D6D;
        max-width: 116px;
        margin-bottom: 10px;
    }

    .rateCase__rating-value {
        font-weight: 400;
        font-size: 46px;
        line-height: 94%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .rateCase__rating-vote {
        width: 315px;
        margin-bottom: 25px;
    }

    .rateCase__rating-grade {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .rateCase__rating-grade > div{
        height: 59px;
        width: 59px;
        background-color: #E9E9E9;
        border-radius: 16px;
    }

    .rateCase__grade-one {
        background: url('../../../Media/OneIconBlack.svg') center no-repeat;
        background-size: auto;
    }

    .rateCase__grade-two {
        background: url('../../../Media/TwoIconBlack.svg') center no-repeat;
        background-size: auto;
    }

    .rateCase__grade-three {
        background: url('../../../Media/ThreeIconBlack.svg') center no-repeat;
        background-size: auto;
    }

    .rateCase__grade-four {
        background: url('../../../Media/FourIconBlack.svg') center no-repeat;
        background-size: auto;
    }

    .rateCase__grade-five {
        background: url('../../../Media/FiveIconBlack.svg') center no-repeat;
        background-size: auto;
    }

    .rateCase__grade-one.grade-selected {
        background: url('../../../Media/OneIconWhite.svg') center no-repeat;
        background-size: auto;
    }

    .rateCase__grade-two.grade-selected {
        background: url('../../../Media/TwoIconWhite.svg') center no-repeat;
        background-size: auto;
    }

    .rateCase__grade-three.grade-selected {
        background: url('../../../Media/ThreeIconWhite.svg') center no-repeat;
        background-size: auto;
    }

    .rateCase__grade-four.grade-selected {
        background: url('../../../Media/FourIconWhite.svg') center no-repeat;
        background-size: auto;
    }

    .rateCase__grade-five.grade-selected {
        background: url('../../../Media/FiveIconWhite.svg') center no-repeat;
        background-size: auto;
    }

    .grade-selected {
        background-color: #141414 !important;
    }

    .rateCase__rating-btn {
        height: 61px;
        width: 100%;
        background: #141414;
        border-radius: 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .rating-btn-disabled {
        color: #6D6D6D;
        pointer-events: none;
    }

    .rateCase__rusult {
        height: 478px;
        padding-top: 25px;
        border-radius: 20px;
    }

    .rateCase__rusult-grade {
        height: 125px;
        width: 125px;
        border-radius: 34px;
        background-color: #141414;
        margin: auto;
        margin-top: 95px;
        margin-bottom: 107px;
    }

    .rateCase__rusult h2 {
        margin-bottom: 18px;
        margin-left: 22px;
        font-weight: 400;
        font-size: 22px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .rateCase__rusult p {
        max-width: 398px;
        margin-left: 24px;
        margin-right: 25px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .rateCase__rusult-text {
        margin: 0 auto !important;
        text-align: center;
        max-width: 290px !important;
    }

    .rateCase__rusult-grade {
        background-size: 53px;
    }

}