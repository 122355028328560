@media screen and (max-width: 743px) {
    
    .caseList__item {
        width: 100%;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
    }
    
    .caseList__item-category {
        width: 100%;
        background-color: #fff;
        border-radius: 20px 20px 0px 0px;
        padding: 30px 25px 80px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .caseList__item-title {
        font-size: 22px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
        max-width: 316px;
        margin-bottom: 18px;
        transition: all 0.5s;
    }
    
    .caseList__item-caption {
        font-size: 14px;
        /* max-width: 240px; */
        transition: all 0.5s;
        color: #6D6D6D;
    }
    
    .caseList__item__cutaway {
        display: flex;
        justify-content: flex-end;
        align-self: flex-end;
        gap: 20px;
    }

    .caseList__item__cutaway.desctop-tablet {
        display: none;
    }
    
    .caseList__item__cutaway p {
        align-self: flex-end;
        text-align: end;
        max-width: 105px;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: #141414;
    }
    
    .caseList__item__qr-code {
        height: 65px;
        width: 65px;
        background: url('../../../Media/BusinessCardLink.svg') center no-repeat;
        background-size: contain;
    }
    
    .caseList__item-case {
        width: 100%;
        max-width: 827px;
        padding: 0px 20px 25px 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 0px 0px 20px 20px;
    }
    
}
