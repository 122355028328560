h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 94%;
  letter-spacing: -0.03em;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #6D6D6D;
}

ul {
 padding-left: 0;
 margin: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  box-sizing: border-box;
  border: none;
  outline: none;
}

textarea {
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 0;
}

textarea::-webkit-scrollbar
{
	width: 5px;
  cursor: default;

}

textarea::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #5B5B5B;
  border-left: 2px solid white;
  border-right: 2px solid white;
  cursor: default;

}

textarea::-webkit-scrollbar-thumb
{
	border-radius: 20px;
	background-color: #141414;
  cursor: default;

}

img {
  user-select: none;
}

.container {
  max-width: 1366px;
  margin: 0 auto;
  padding: 0px 10px;
  box-sizing: border-box;
}

@media screen and (min-width: 960px) {
  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0px 10px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 744px) {
  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0px 5px;
    box-sizing: border-box;
  }
}
