.header-mobile {
    padding-top: 5px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
    box-sizing: border-box;
    display: block;
    background: linear-gradient(to bottom, #E9E9E9 40px, transparent 40px);
}

.header-mobile__content {
    width: 100%;
    border-radius: 20px;
}

.header-mobile__head {
    height: 65px;
    width: 100%;
    background: #fff;
    border-radius: 20px;
    padding: 0px 28px 0px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-mobile__button  {
    z-index: 100;
    display: block;
    position: relative;
    width: 29px;
    height: 11px;
    cursor: pointer;
    float: right;
}

.header-mobile__button::before,
.header-mobile__button::after {
    left: 0;
    position: absolute;
    height: 20%;
    width: 100%;
    
    transition: all 0.3s ease 0s;
    background-color: #131313;
}

.header-mobile__button::before,
.header-mobile__button::after {
    content: "";
}

.header-mobile__button::before {
    top: 0;
}

.header-mobile__button::after {
    bottom: 0;
}

.header-mobile__button._active::before {
    top: 61%;
    transform: rotate(-45deg) translate(0px, -61%);
}

.header-mobile__button._active::after {
    top: 49%;
    transform: rotate(45deg) translate(0px, 49%);
}

.header-mobile__logo-link {
    height: 26px;
    width: 110px;
}

.header__logo {
    height: 26px;
    width: 110px;
}

.header-mobile__body {
    position: fixed;
    top: 0px;
    left: 0;
    padding: 0px 5px;
    width: 100%;
    height: 300px;
    border: 1px solid black;
    z-index: 990;
}

.header-mobile__nav {
    height: 100%;
    width: 100%;
    border: 1px solid black;
    background-color: #fff;
}

.mobile-menu__wrapper {
    position: fixed;
    top: -100%;
    left: 0;
    background-color: transparent;
    padding: 0px 10px;
    width: 100%;
    height: 100%;
    transition: top 0.4s ease 0s;
    z-index: 998;
    overflow: auto;
}

.mobile-nav-active {
    top: 0;
    background-color: #E9E9E9;
}

.mobile-menu__navigation {
    height: calc(100% - 162px);
    min-height: 390px;
    border-radius: 20px;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 94px 30px 0px 30px;
}

.mobile-menu__nav-list {
    border-top: 1px solid #E7E7E7;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #141414;
}

.mobile-menu__nav-list-item {
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E7E7E7;
}

.mobile-menu__service {
    margin-top: 30px;
}

.mobile-menu__service label {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #6D6D6D;
}

.mobile-menu__service-list {
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.mobile-menu__service-list-item {
    height: 20px;
    width: 50%;
    margin-bottom: 29px;
    display: flex;
    align-items: center;
}

.nav-icon {
    height: 20px;
    width: 20px;
}

.nav-icon-interactive {
    background: url('../../../Media/NavIconInteractive.svg') center no-repeat;
    background-size: contain;
}

.nav-icon-business {
    background: url('../../../Media/NavIconBusiness.svg') center no-repeat;
    background-size: contain;
}

.nav-icon-xr {
    background: url('../../../Media/NavIconXr.svg') center no-repeat;
    background-size: contain;
}

.nav-icon-online {
    background: url('../../../Media/NavIconXr.svg') center no-repeat;
    background-size: contain;
}

.mobile-menu__service-list-item a {
    display: flex;
    gap: 25px;
}

.mobile-menu__bottom {
    background-color: #fff;
    height: 132px;
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
}

.mobile-menu__bottom-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #6D6D6D;
}

.mobile-menu__bottom-link:hover {
    text-decoration: underline;
}

.mobile-menu__soc-network {
    align-self: flex-end;
    display: flex;
}

.mobile-menu__soc-netw {
    display: block;
    height: 38px;
    width: 38px;
    border: 1px solid #BDBDBD;
    border-radius: 12px;
    transition: background-color 0.3s;
}

.mobile-menu__soc-netw:hover {
    background-color: #BDBDBD;
}

.telegram {
    background: url('../../../Media/TelegramIcon.svg') center no-repeat;
    background-size: 17px;
}

.youtube {
    background: url('../../../Media/YouTubeIcon.svg') center no-repeat;
    background-size: 17px;;
}

@media screen and (max-width: 744px) {
    .mobile-menu__wrapper {
        padding: 0px 5px;
    }

    .mobile-menu__navigation {
        margin-bottom: 5px;
    }
}