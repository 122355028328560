@media screen and (max-width: 744px) {

    .caseHeader__banner {
        height: 250px;
        width: 100%;
        padding: 40px 0px 0px 40px;
        margin: 5px 0px;
        border-radius: 20px; 
        display: flex;
        justify-content: space-between;  
        position: relative;
        overflow: hidden;
    }
    
    .caseHeader__img {
        height: 100%;
        width: 100%;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        object-fit:cover;
        z-index: -1;
    }

}