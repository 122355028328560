.visitingCard__container {
    padding-top: 100px;
    height: calc(100vh - 97px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.visitingCard__container h1 {
    align-self: flex-start;
    margin-left: 22px;
    margin-bottom: 30px;
}

.visitingCard__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.visitingCard {
    height: 222px;
    width: 355px;
    padding: 25px;
    background-color: #141414;
    border-radius: 11px;
    color: #ffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.visitingCard__logo {
    height: 32px;
    width: 137px;
}

.visitingCard__contacts {
    display: flex;
    justify-content: space-between;
}

.visitingCard__btn {
    height: 61px;
    width: 315px;
    border: 1px solid #141414;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}