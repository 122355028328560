@import url('./ContactsHead_744.css');

@media screen and (min-width: 960px) {
    
    .contactsHead__wrapper {
        padding: 10px 0px 10px 0px;
        display: flex;
        gap: 10px;
    }

    .contactsHead__banner {
        height: 600px;
        width: 509px;
        min-width: 509px;
        padding: 40px 0px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .contactsHead__banner-top {
        display: flex;
        justify-content: space-between;
    }

    .contactsHead__banner-top a {
        height: 32px;
    }

    .contactsHead__logo {
        height: 32px;
        margin-left: 40px;
        align-self: start;
        transition: all 0.5s;
    }

    .contactsHead__banner-bottom {
        display: flex;
        justify-content: space-between;
    }

    .contactsHead__banner-titles h1 {
        margin-left: 37px;
        margin-bottom: 18px;
        font-weight: 400;
        font-size: 46px;
        line-height: 94%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .contactsHead__banner-titles p {
        margin-left: 39px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
        max-width: 210px;
    }

    .contactsHead__banner-cutaway {
        align-self: flex-end;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .contactsHead__banner-cutaway p {
        max-width: 41px;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        text-align: right;
        color: #141414;
        align-self: flex-end;
    }

    .contactsHead__banner-qr {
        height: 65px;
        width: 65px;
        /* background: url('../../../Media/PresentationQr.svg') center no-repeat; */
        background-size: contain;
    }

    .contactsHead__map {
        position: relative;
        height: 600px;
        width: 100%;
        max-width: 828px;
        background-color: black;
        border-radius: 20px;
        -webkit-user-select: none;
        user-select: none;
        /* position: relative; */
        overflow: hidden;
    }

    .contactsHead__map img {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        user-select: none;
    }

}

@media screen and (min-width: 744px) and (max-width: 960px) {
    .contactsHead__wrapper {
        padding: 10px 0px 10px 0px;
        gap: 10px;
    }
}