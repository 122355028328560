@media screen and (min-width: 744px) and (max-width: 959px) {
  
    .readyMechanics__wrapper {
        background-color: #141414;
        padding: 20px 0px 30px 0px;
        border-radius: 20px;
    }

    .readyMechanics__title {
        color: #fff;
        font-weight: 400;
        font-size: 42px;
        line-height: 94%;
        letter-spacing: -0.03em;
        margin-left: 36px;
        margin-bottom: 15px;
    }

    .readyMechanics__caption {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #BDBDBD;
        margin-left: 40px;
        max-width: 245px;
        margin-bottom: 100px;
    }

    .readyMechanics__list {
        padding: 0px 30px 0px 30px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .readyMechanics__list-item {
        flex: 49%;
        max-width: 50%;
        margin-bottom: 30px;
    }

}

