@media screen and (min-width: 744px) and (max-width: 959px) {

    .feedbackForm {
        height: 523px;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        border-radius: 20px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: column;
    }

    .feedbackForm form {
        position: relative;
        margin-bottom: 25px;
    }
    
    .feedbackForm__heading {
        height: 64px;
        min-height: 64px;
        display: flex;
        align-items: center;
        padding-left: 24px;
        border-bottom: 1px solid #E7E7E7;
    }
    
    .feedbackForm__heading-title {
        font-family: 'TT Hoves', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #6D6D6D;
        margin-right: 10px;
    }
    
    .feedbackForm__heading-request {
        height: 36px;
        width: 87px;
        background: #E9E9E9;
        border-radius: 30px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .feedbackForm__input {
        font-family: 'TT Hoves', sans-serif;
        width: 100%;
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #141414;
        box-sizing: border-box;
        padding: 22px 61px 21px 24px;
        resize: none;
        border-bottom: 1px solid #E7E7E7;
    }

    .feedbackForm__input.input-error {
        color: #FF3641;
        padding: 24px 61px 19px 24px;
    }

    .feedbackForm__input::placeholder {
        color: #6D6D6D;
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        letter-spacing: -0.02em;
    }

    .feedbackForm__input.input-error::placeholder {
        color: #FF3641;
    }

    .feedbackForm__textariablock {
        margin: 20px 25px 0px 24px;
        height: 122px;
    }

    .feedbackForm__input.textaria {
        padding: 0px 26px 0px 0px;
        width: 100%;
        height: 100%;
        border-bottom: none;
    }

    .textaria.input-error {
        padding-top: 2px;
    }

    .error-message {
        position: absolute;
        display: block;
        font-weight: 400;
        font-size: 11px;
        line-height: 110%;
        color: #6D6D6D;
        left: 24px;
    }

    .error-message.name {
        top: 12px;
    }
    .error-message.email {
        top: 76px;
    }
    .error-message.phone {
        top: 138px;
    }
    .error-message.message {
        top: 202px;
    }

    .feedbackForm__bottom {
        width: 100%;
        height: 76px;
        padding: 0px 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .feedbackForm__rules {
        max-width: 223px;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .feedbackForm__rules a {
        text-decoration: underline;
    }

    .feedbackForm__button {
        height: 65px;
        width: 65px;
        border-radius: 20px;
        background: url('../../../Media/ButtonArrow.svg') center no-repeat;
        background-size: 23px;
        background-color: #141414;
        cursor: pointer;
    }

    .feedbackForm__button.fb-disabled {
        background: url('../../../Media/ButtonArrowDisabled.svg') center no-repeat;
        background-size: 23px;
        background-color: #141414;
        pointer-events: none;
    }

    /* .feedbackForm__button:hover {
        height: 71px;
        width: 71px;
    } */

    .feedback__sent {
        height: 100%;
        width: 100%;
        padding-top: 25px;
        padding-right: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .feedback__sent h2 {
        font-weight: 400;
        font-size: 42px;
        line-height: 94%;
        letter-spacing: -0.03em;
        color: #141414;
        margin-left: 22px;
        margin-bottom: 15px;
    }

    .feedback__sent p {
        flex-grow: 1;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
        margin-left: 24px;
    }

    .feedback__sent .feedbackForm__button {
        align-self: flex-end;
        background: url('../../../Media/ButtonSuccess.svg') center no-repeat;
        background-size: 23px;
        background-color: #141414;
    }

    .feedbackForm__required {
        position: absolute;
        right: 40px;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
    }
}