@media screen and (min-width: 960px) and (max-width: 1123px) {
    
    .competenceHead__wrapper {
        display: flex;
        gap: 10px;
        padding: 10px 0px;
    }
    
    .competenceHead__banner {
        height: 500px;
        width: 100%;
        max-width: 576px;
        padding: 40px 0px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .competenceHead__banner-top {
        display: flex;
        justify-content: space-between;
    }

    .competenceHead__banner-top a {
        height: 32px;
    }
    
    .competenceHead__logo {
        height: 32px;
        margin-left: 30px;
        align-self: start;
        transition: all 0.5s;
    }
    
    .competenceHead__title {
        margin-left: 27px;
        margin-bottom: 18px;
        transition: all 0.5s;
    }
    
    .competenceHead__label {
        margin-left: 30px;
        max-width: 430px;
        transition: all 0.5s;
    }
    
    .competenceHead__card {
        height: 500px;
        width: 508px;
        padding: 47px 30px 40px 30px;
        background-color: #141414;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s;
    }
    
    .competenceHead__card p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #fff;
    }
    
    .competenceHead__card-label-top {
        align-self: flex-end;
        /* margin-bottom: 126px; */
    }
    
    .competenceHead__card-icon {
        height: 120px;
        width: 120px;
        border-radius: 33px;
        background-size: 50px;
        /* margin-bottom: 116px; */
    }
    
    .competenceHead__card-label-bottom {
        align-self: flex-start;
        max-width: 182px;
    }

}
