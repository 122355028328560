@media screen and (max-width: 960px) {
    
    .contactsWriteUs__wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .contactsWriteUs__info {
        height: 418px;
        width: 100%;
        padding: 30px 24px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .contactsWriteUs__title h2 {
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #141414;
    }

    .contactsWriteUs__title p {
        max-width: 220px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #6D6D6D;
    }

    .contactsWriteUs__data {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .contactsWriteUs__phone, .contactsWriteUs__email {
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
    }

    .contactsWriteUs__email {
        text-decoration: underline;
    }

}