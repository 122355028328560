@import url("./TopBlock_960.css");
@import url("./TopBlock_744.css");
@import url("./TopBlock_375.css");

@media screen and (min-width: 1280px) {

    .topBlock__wrapper {
        height: 100vh;
        max-height: 900px;
        min-height: 656px;
        width: 100%;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        box-sizing: border-box;
     }
    
    .topBlock__banner {
        height: calc(100vh - 254px);
        max-height: 646px;
        min-height: 402px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        border-radius: 20px;
        padding: 40px 0px 30px 0px;
        width: calc(50% - 5px);
        max-width: calc(50% - 5px);
        margin-right: 5px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }
    
    .topBlock__logo {
        height: 32px;
        margin-left: 40px;
        align-self: start;
        transition: all 0.5s;
    }
    
    .topBlock__banner-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .topBlock__banner-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .topBlock__nav-wrapper {
        align-self: flex-end;
    }
    
    .topBlock__titles {
        margin-bottom: 10px;
    } 
    
    .topBlock__title {
        margin-bottom: 18px;
        margin-left: 37px;
    }
    
    .topBlock__label {
        margin-left: 39px;
    }
    
    .topBlock__stamp {
        background: url('../../../Media/stamp.png') center no-repeat;
        background-size: contain;
        height: 117px;
        width: 117px;
        align-self: flex-end;
        margin-right: 40px;
        transition: all 0.5s;
    }
    
    .topBlock__partners {
        height: 224px;
        width: calc(50% - 5px);
        max-width: calc(50% - 5px);
        background-color: #141414;
        border-radius: 20px;
        padding: 25px 0px;
        box-sizing: border-box;
    }
    
    .topBlock__partners-title {
        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        margin-left: 39px;
        margin-bottom: 20px;
    }
    
    .topBlock__partners-label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        align-items: flex-end;
        color: #BDBDBD;
        margin-left: 39px;
    }

    .topBlock__ticker {
        position: relative;
        display: flex;
        overflow: hidden;
        user-select: none;
        gap: 70px;
        height: 100px;
        width: 100%;
        margin-bottom: 18px;
        box-sizing: border-box;
    }

    .topBlock__ticker-items {
        flex-shrink: 0;
        display: flex;
        gap: 70px;
        box-sizing: border-box;
      }
      
    .topBlock__ticker-item {
        height: 100px;
        /* transition: all 0.1s ease-in-out; */
    }
    
    .marquee {
        animation: scroll 120s linear infinite;
    }
      
    @keyframes scroll {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(calc(-100% - 70px));
        }
    }

    .topBlock__slider {
        height: 100%;
        min-height: 636px;
        width: calc(50% - 5px);
        max-width: calc(50% - 5px);
        margin-left: 5px;
        box-sizing: border-box;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        background-color: #141414;
    }

    .swiper {
        height: 100%;
        width: 100%;
        border-radius: 20px;
    }

    .swiper-slide {
        border-radius: 20px;
    }

    .topBlock__slider-title {
        position: absolute;
        color: #fff;
        z-index: 99;
        top: 47px;
        left: 50px;
        font-size: 16px;
    }

    .topBlock__slide {
        height: 100%;
        width: 100%;
        border-radius: 20px;
        padding: 0px 40px 52px 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 !important;
        margin-bottom: 18px;
      }
    
    
      .swiper-pagination-bullet {
        width: 40px !important;
        height: 2px !important;
        margin: 0 !important;
        opacity: 1;
        border-radius: 0px !important;
        background-color: white !important;
      }
      
      .swiper-pagination-bullet-active {
        height: 4px !important;
        border-radius: 10px !important;
        background: white !important;
      }

      .topBlock__slide__card {
        width: 100%;
        height: 116px;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 26px;
        display: flex;
        gap: 22px;
        padding: 30px 30px 0px 30px;
      }

      .topBlock__slide__card-icon {
        height: 56px;
        width: 56px;
        background-color: #141414;
        border-radius: 16px;
      }

      .topBlock__slide__card-title {
        margin-top: 3px;
        flex-grow: 1;
      }

      .topBlock__slide__card-title h3 {
        font-weight: 500;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #141414;
      }

      .topBlock__slide__card-title p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6D6D6D;
        max-width: 285px;
      }

      .topBlock__slide__cart-case {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8px;
      }

      .topBlock__slide__card-case__count {
        font-weight: 500;
        font-size: 22px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        color: #141414;
        /* margin-bottom: 4px; */
      }

      .topBlock__slide__card-case__name {
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        text-align: center;
        color: #6D6D6D;
      }

}
